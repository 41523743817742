import * as React from "react";
import styled from "@emotion/styled";
import { useRef, useState, useEffect } from "react";
import { screenSize } from "../../utils/css/themes";

const FacebookContainerDiv = styled.div`
  margin: auto;
`;

const StyledContainerSpan = styled.span`
  max-width: 100%;
  width: 100%;
  display: inline-block;
  margin-bottom: 1rem;
  @media (max-width: ${screenSize.largeTablet}) {
    width: 100%;
    display: block;
  }
  iframe {
    margin: auto;
    max-width: 500px;
    display: block;
    border-radius: 10.5px;
    box-shadow: 2px 6px 23px 0 rgba(0, 0, 0, 0.2);
    border: solid 0.6px #d7dae1;
  }
`;

const StyledIFrame = styled.iframe`
  border: none;
  overflow: hidden;
`;

const getHandleFromPage = (page: string): string => {
  // If the input starts with an "@", assume it's already a handle and return it
  if (page.startsWith("@")) {
    return page.substring(1); // Remove the "@" for the Facebook plugin URL
  }

  // If it's a URL (starts with "http://" or "https://"), process as a URL
  const pageWithProtocol =
    page.startsWith("http://") || page.startsWith("https://")
      ? page
      : `http://${page}`;

  try {
    // Attempt to create a URL object from the input string
    const url = new URL(pageWithProtocol);
    // Extract the last part of the pathname
    const lastPart = url.pathname
      .split("/")
      .filter((part) => part !== "")
      .pop();
    // Return the page handle without any "@" prefix for Facebook plugin URL
    return lastPart || "";
  } catch (error) {
    // If the input string is not a valid URL, assume it's a plain page handle
    return page;
  }
};

const FacebookTimeline = (props: FacebookProps) => {
  const iframeContainer = useRef(null);
  const [iframeWidth, setIframeWidth] = useState(500);

  var iFrameSrc =
    "https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2F" +
    getHandleFromPage(props.page) +
    "&tabs=timeline&small_header=true&adapt_container_width=true&hide_cover=true&show_facepile=true&appId=475838662997369&height=500&width=" +
    iframeWidth;

  // maintain iframe at 500 pixel width
  useEffect(() => {
    setIframeWidth(
      iframeContainer.current.offsetWidth >= 500
        ? 500
        : iframeContainer.current.offsetWidth
    );
  }, [iframeContainer]);

  return (
    <StyledContainerSpan ref={iframeContainer}>
      <FacebookContainerDiv>
        <StyledIFrame
          title="Facebook Timeline iFrame"
          tabIndex={-1}
          src={iFrameSrc}
          width={`${iframeWidth}`}
          height="500"
          scrolling="no"
          allow="encrypted-media"
        />
      </FacebookContainerDiv>
    </StyledContainerSpan>
  );
};

interface FacebookProps {
  page: "facebook";
}

export default FacebookTimeline;
